import { ApolloError, ServerError, ServerParseError } from '@apollo/client';
import { t } from 'i18next';
import { IFormikValues } from 'src/features/CountersAdministration/CreateCounterForm/useStepTwo';
import { IEventFormikValues } from 'src/features/Dashboard/DashboardEvents/EditEventModal/useEditEventModal';

const PERMISSION_DENIED_MESSAGE = 'PermissionDenied';
const VALIDATION_ERROR_MESSAGE = 'Validation error';
const COUNTER_NOT_FOUND_MESSAGE = 'Counter not found';
const DASHBOARD_VALIDATION_ERROR_MESSAGE = 'Dashboard name is already taken';
const USER_EVENT_ALREADY_EXISTS = 'User event already exists';
const USER_EVENT_NAME_ALREADY_EXISTS = 'User event name already exists';
const PROJECT_PAAS_KEY_ALREADY_EXISTS = 'Project paas key already exists';

export const getUserEventAlreadyExistsError = (
  errorText: string,
): { field: keyof IEventFormikValues; errorMsg: string } | null => {
  switch (errorText) {
    case USER_EVENT_NAME_ALREADY_EXISTS: {
      return {
        field: 'name',
        errorMsg: t('dashboard.events.addEventForm.userEventNameAlreadyExists'),
      };
    }
    case USER_EVENT_ALREADY_EXISTS:
      return {
        field: 'codename',
        errorMsg: t('dashboard.events.addEventForm.userEventAlreadyExists'),
      };
    default:
      return null;
  }
};

export const getStepTwoAlreadyExistsError = (
  errorText: string,
): { field: keyof IFormikValues; errorMsg: string } | null => {
  if (errorText === PROJECT_PAAS_KEY_ALREADY_EXISTS) {
    return {
      field: 'paasKey',
      errorMsg: t('countersAdministration.createCounterModal.step2.errorTextPaasKeyAlreadyExists'),
    };
  }

  return null;
};

export function isServerError(error: Error | ServerParseError | ServerError | null): error is ServerError {
  if (!error) {
    return false;
  }

  return !!(error as ServerError).result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrors(error: ApolloError): any {
  if (isServerError(error.networkError)) {
    // eslint-disable-next-line
    return error.networkError.result.errors?.find((err: Error) => err.message === VALIDATION_ERROR_MESSAGE);
  }

  return undefined;
}

export interface IValidationErrorExtension<L> {
  loc: L;
  msg: string;
}

const createError = (errorMessage: string) => (error: ApolloError) => {
  if (isServerError(error.networkError)) {
    // eslint-disable-next-line
    return !!error.networkError.result.errors?.find((err: Error) => err.message.includes(errorMessage));
  }

  return error?.message.includes(errorMessage);
};

export const isPermissionDeniedError = createError(PERMISSION_DENIED_MESSAGE);

export const counterNotFound = createError(COUNTER_NOT_FOUND_MESSAGE);

export const isDashboardValidationError = createError(DASHBOARD_VALIDATION_ERROR_MESSAGE);

export const isDashboardValidationLengthError = createError(VALIDATION_ERROR_MESSAGE);
