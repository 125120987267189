import i18n from 'i18next';
import i18next from 'i18next';
import { getMonthsDaysAndYearsFromSeconds } from 'src/helpers/getDaysMonthsYearsFromSeconds';

const OPTIONS: { [key: string]: 'numeric' } = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

function serverTimeToClientTime(serverTime: number): number {
  return serverTime * 1000;
}

function clientTimeToServerTime(clientTime: number): number {
  return clientTime / 1000;
}

function dateTimeToNumber(serverTime: string): number {
  return new Date(serverTime).getTime();
}

function dateToISOLocal(date: Date): string {
  const z = (n: number) => `0${n}`.slice(-2);
  const zz = (n: number) => `00${n}`.slice(-3);

  return `${date.getFullYear()}-${z(date.getMonth() + 1)}-${z(date.getDate())}T${z(date.getHours())}:${z(
    date.getMinutes(),
  )}:${z(date.getSeconds())}.${zz(date.getMilliseconds())}`;
}

function dateUTCtoServerDateTime(date: Date): string {
  return date.toISOString().split('.')[0];
}

function dateLocaltoServerDateTime(date: Date): string {
  return date.toISOString().split('.')[0].substring(0, 11) + date.toLocaleTimeString();
}

function formatNumber(value: number): string {
  return value.toLocaleString(i18n.language, { maximumFractionDigits: 2 });
}

function formatDate(timestamp: number): string {
  return new Date(serverTimeToClientTime(timestamp)).toLocaleString(i18n.language, OPTIONS);
}

function isoWithoutTimeZone(clientTime: number): string {
  const date = new Date(clientTime);

  const addLeadingZero = (n: number) => `0${n}`.slice(-2);
  const addDoubleLeadingZero = (n: number) => `00${n}`.slice(-3);

  return `${date.getFullYear()}-${addLeadingZero(date.getMonth() + 1)}-${addLeadingZero(
    date.getDate(),
  )}T${addLeadingZero(date.getHours())}:${addLeadingZero(date.getMinutes())}:${addLeadingZero(
    date.getSeconds(),
  )}.${addDoubleLeadingZero(date.getMilliseconds())}`;
}

function formatTimestampDate(timestamp: number | string): string {
  return new Intl.DateTimeFormat(i18n.language, OPTIONS).format(new Date(timestamp));
}

function timestampDateToFormat(timestamp: number | string): string {
  const date = new Date(timestamp);
  const addLeadingZero = (n: number) => `0${n}`.slice(-2);

  return `${addLeadingZero(date.getDate())}.${addLeadingZero(
    date.getMonth() + 1,
  )}.${date.getFullYear()}, ${addLeadingZero(date.getHours())}:${addLeadingZero(date.getMinutes())}`;
}

function formatDateToHumanity(timestamp: number, translationKey: string): string {
  const { years, months, days } = getMonthsDaysAndYearsFromSeconds(timestamp);

  const getLabel = (key: string, value: number) => i18next.t(key, { postProcess: 'interval', count: value });

  const yearsString = years ? [years, getLabel(`${translationKey}.years`, years)].join(' ') : '';
  const monthsString = months ? [months, getLabel(`${translationKey}.months`, months)].join(' ') : '';
  const daysString = [days, getLabel(`${translationKey}.days`, days)].join(' ');

  return `${yearsString} ${monthsString} ${daysString}`;
}

function daysToSeconds(days: number): number {
  return days * 86_400;
}

export const Formatters = {
  date: { seconds: formatDate, timestamp: formatTimestampDate, fullDateAsString: formatDateToHumanity, daysToSeconds },
  number: formatNumber,
  serverTimeToClientTime,
  dateTimeToNumber,
  clientTimeToServerTime,
  dateUTCtoServerDateTime,
  isoWithoutTimeZone,
  dateLocaltoServerDateTime,
  dateToISOLocal,
  timestampDateToFormat,
};
