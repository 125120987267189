import { Role } from 'src/typings/eventTypes';

export enum USER_ROLES {
  admin = 'admin',
  user = 'user',
  security_guard = 'security_guard',
}

export class RoleValidator {
  isSpaAdmin(role: Role): boolean {
    return Boolean(this.isProjectAdmin(role) && !role.projectCode);
  }

  isProjectAdmin(role: Role): boolean {
    return role?.name === USER_ROLES.admin;
  }

  isSpaUser(role: Role): boolean {
    return (role?.name === USER_ROLES.user || role?.name === USER_ROLES.security_guard) && !role.projectCode;
  }
}

class RolesFilter {
  constructor(private _validator: RoleValidator) {}

  isSpaAdmin(roles: Array<Role>): boolean {
    return Boolean(roles.find((role) => this._validator.isSpaAdmin(role)));
  }

  isProjectAdmin(roles: Array<Role>): boolean {
    return Boolean(roles.find((role) => this._validator.isProjectAdmin(role)));
  }

  isSpaUser(roles: Array<Role>): boolean {
    return Boolean(roles.find((role) => this._validator.isSpaUser(role)));
  }
}

export const rolesFilter = new RolesFilter(new RoleValidator());
