import { genericPromiseCatch } from './helpers';

const init = async () => {
  /* import KeycloakService from 'keycloak-service';
     await KeycloakService.init();
  */

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const render = await import('src/mainPage');

  render.renderApp();
};

init().catch(genericPromiseCatch);

export default {};
