import { TFunction } from 'i18next';

export const mailSPA = 'Spa@gazprom-neft.ru';

export function getHelpLink(t: TFunction): string {
  return `mailto:${mailSPA}?subject=${t('common.helpEmailSubject')}`;
}

export function getConfluenceHelpLink(): string {
  return 'https://kb.gazprom-neft.local/pages/viewpage.action?pageId=26937663';
}

export const getConfluenceReportLink = (): string => {
  return 'http://selfservice.gazprom-neft.local/SitePages/Home20.aspx#/lk/s2%7Cs3%7C49%7C545';
}
