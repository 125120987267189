import { IDateGroup } from 'src/helpers/types';

const secondsInDay = 86_400;
const daysInMonth = 30;
const daysInYear = 365;

function getDaysFromSeconds(seconds: number) {
  return Math.floor(seconds / secondsInDay);
}

export function getMonthsDaysAndYearsFromSeconds(seconds: number): IDateGroup {
  let days = getDaysFromSeconds(seconds);

  const years = Math.floor(days / daysInYear);

  days = days % daysInYear;

  const months = Math.floor(days / daysInMonth);

  days = days % daysInMonth;

  return {
    days,
    months,
    years,
  };
}
