import { TFunction } from 'i18next';

const addLeadingZero = (n: number) => `0${n}`.slice(-2);

export function parseTime(totalSecondsNumber: number, t?: TFunction, cutSeconds?: boolean): string {
  const totalMinutesNumber = Math.floor(Math.abs(totalSecondsNumber) / 60);

  const hours = Math.floor(totalMinutesNumber / 60);
  const minutes = totalMinutesNumber % 60;
  const seconds = Math.floor(Math.abs(totalSecondsNumber)) % 60;

  const sign = totalSecondsNumber < 0 ? '-' : '';

  if (t) {
    return `${hours ? t('common.timeFormat.hours', { hours }) : ''} ${
      minutes ? t('common.timeFormat.minutes', { minutes }) : ''
    } ${hours && cutSeconds ? '' : t('common.timeFormat.seconds', { seconds })}`;
  }
  const minutesToView = minutes ? addLeadingZero(Math.abs(minutes)) : '00';
  const secondsToView = seconds ? addLeadingZero(Math.abs(seconds)) : '00';

  return `${sign}${hours}:${minutesToView}:${secondsToView}`;
}
